import '../Assets/Css/account.css'
import { CircularProgress } from '@material-ui/core';
import React, { useState } from 'react';
import { useCookies } from 'react-cookie';
import {Noti} from './ContactUs'
import axios from "axios"
import {gurl} from './Global'
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import {Link} from "react-router-dom";

export default function Authentication(){
	const [cookies] = useCookies(['user']);
	return(
		<div>
			
			{
				cookies.user?
				<Home/>
				:
				<Account/>
			}
			
		</div>
	)
}


function Account(){
	const [cookies, setCookie] = useCookies(['user']);
	const [isActive, setActive] = useState(false);
	const [signins, setSignins] = useState('');
	const [signups, setSignups] = useState('');
	console.log(cookies.user,cookies.accounts)
	const toggleClass = () => {
    setActive(!isActive);
  };

  const Signup=event=>{
	event.preventDefault();
	var password = document.getElementById("password")
	, confirm_password = document.getElementById("confirm_password");
  
	if(password.value !== confirm_password.value) {
	  confirm_password.setCustomValidity("Passwords Don't Match");
	  
	} else {
	  confirm_password.setCustomValidity('');
	  var name = document.getElementById("name").value
	  var email = document.getElementById("email").value
	  console.log(password.value,name,email)
	  var bodyFormData=new FormData();
	  bodyFormData.append('name',name)
	  bodyFormData.append('email',email)
	  bodyFormData.append('password',password.value)
	  axios.post(gurl+'account/signup',bodyFormData).then((data)=>{
		  console.log(data);
		  setSignups(0)
		  Noti('signup success plz signin','success')
		  toggleClass()
		})
	  .catch((error)=> {
		console.log(error.response.data);
		Noti('try again','warning')
		setSignups(0) 
		toggleClass()

	  });
	  setSignups(1)
	}
  }

  const Signin=event=>{
	event.preventDefault();
	  var password = document.getElementById("spassword").value
	  var email = document.getElementById("semail").value
	  var bodyFormData=new FormData();
	  bodyFormData.append('username',email)
	  bodyFormData.append('password',password)
	  bodyFormData.append('grant_type','password')
	  bodyFormData.append('client_id','KRmvNo7vvuCXstmQifZCM03HqyAp48NmM5kdi4NU')
	  bodyFormData.append('client_secret','p92QCs5ggiLxYt32aQNjlvuZi7A0umVMfUEVokOqdsZiYOv0PxvD4900BO7LogDp4uMKnYb6Yd3PWd1kTUw6EeSJUB8N2SXBBgNHlh4YhcjqqWj8ySoy3Jq9H4bafxLR')
	  axios.post(gurl+'auth/token',bodyFormData).then((response)=> {
		User(response.data.access_token,'Account')
	  })
	  .catch((error)=> {
		console.log(error.response.data.error_description);
		setSignins(0) 
	  });
	  setSignins(1)	  
  	}

	const responseFacebook=(res)=>{
		if(res.accessToken){
			console.log(res.accessToken)
		
		
		var bodyFormData=new FormData();
	  bodyFormData.append('token',res.accessToken)
	  bodyFormData.append('backend','facebook')
	  bodyFormData.append('grant_type','convert_token')
	  bodyFormData.append('client_id','KRmvNo7vvuCXstmQifZCM03HqyAp48NmM5kdi4NU')
	  bodyFormData.append('client_secret','p92QCs5ggiLxYt32aQNjlvuZi7A0umVMfUEVokOqdsZiYOv0PxvD4900BO7LogDp4uMKnYb6Yd3PWd1kTUw6EeSJUB8N2SXBBgNHlh4YhcjqqWj8ySoy3Jq9H4bafxLR')
	  axios.post(gurl+'auth/convert-token',bodyFormData).then((response)=> {
		User(response.data.access_token,'Facebook')
	  })
	  setSignins(1)
	}
  }
	const responseGoogle=(res)=>{
		if(res.accessToken){
			console.log(res.accessToken,'bb')
		
		var bodyFormData=new FormData();
	  bodyFormData.append('token',res.accessToken)
	  bodyFormData.append('backend','google-oauth2')
	  bodyFormData.append('grant_type','convert_token')
	  bodyFormData.append('client_id','KRmvNo7vvuCXstmQifZCM03HqyAp48NmM5kdi4NU')
	  bodyFormData.append('client_secret','p92QCs5ggiLxYt32aQNjlvuZi7A0umVMfUEVokOqdsZiYOv0PxvD4900BO7LogDp4uMKnYb6Yd3PWd1kTUw6EeSJUB8N2SXBBgNHlh4YhcjqqWj8ySoy3Jq9H4bafxLR')
	  axios.post(gurl+'auth/convert-token',bodyFormData).then((response)=> {
		User(response.data.access_token,'Google')
	  })
	  setSignins(1)
	}
	}
	// const authHandler=(res)=>{
	// 	console.log(res)
	// 	var bodyFormData=new FormData();
	//   bodyFormData.append('token',res.accessToken)
	//   bodyFormData.append('backend','google-oauth2')
	//   bodyFormData.append('grant_type','convert_token')
	//   bodyFormData.append('client_id','KRmvNo7vvuCXstmQifZCM03HqyAp48NmM5kdi4NU')
	//   bodyFormData.append('client_secret','p92QCs5ggiLxYt32aQNjlvuZi7A0umVMfUEVokOqdsZiYOv0PxvD4900BO7LogDp4uMKnYb6Yd3PWd1kTUw6EeSJUB8N2SXBBgNHlh4YhcjqqWj8ySoy3Jq9H4bafxLR')
	//   axios.post(gurl+'auth/convert-token',bodyFormData).then((response)=> {
	// 	User(response.data.access_token)
	//   })
	//   setSignins(1)
	// }

	const User=(access_token,provider)=>{
	var bodyFormData=new FormData();
	bodyFormData.append('access_token',access_token)
	axios.post(gurl+'account/get-user',bodyFormData).then((response)=> {
		console.log(response.data[0]);
		var userdata={'access_token':access_token,'email':response.data[0].email,'first_name':response.data[0].first_name,'last_name':response.data[0].last_name,'username':response.data[0].username,'provider':provider}
		setCookie('user', userdata);
		setSignins(0)
		Noti('signin success','success')
		window.location.reload();
	})
	}

    return(
      
            
		<div className='acc'>
		{/* <h2>Weekly Coding Challenge #1: Sign in/up Form</h2> */}
		<div id="container" className={isActive ? 'container right-panel-active': "container"}>
			<div className="form-container sign-up-container">
				<form onSubmit={Signup}>
					<h2>Create Account</h2>
					{/* <div className="social-container">
						<a href="#" className="social"><i className="fab fa-facebook-f"></i></a>
						<a href="#" className="social"><i className="fab fa-google-plus-g"></i></a>
						<a href="#" className="social"><i className="fab fa-linkedin-in"></i></a>
					</div> */}
					{/* <span>or use your email for registration</span> */}
					<input type="text" id='name' placeholder="Name" />
					<input type="email" id='email' placeholder="Email" />
					<input type="password" id='password' placeholder="Password" />
					<input type="password" id='confirm_password' placeholder="Confirm Password" />
					<button type="submit">Sign Up</button>
					{
						signups===1?
						<CircularProgress color="secondary" />
						:
						<></>
					}
				</form>
				
			</div>
			
			<div className="form-container sign-in-container">
				<form onSubmit={Signin}>
					<h2>Sign in with</h2>
					<div className="social-container">
						
							<FacebookLogin
							appId="482811786156180"
							fields="name,picture"
							callback={responseFacebook}
							// buttonText="Login"
							render={renderProps => (
						<Link className="social" onClick={renderProps.onClick}><i className="fab fa-facebook-f"></i></Link>
							)}
							/>																
						
							<GoogleLogin
							clientId="684458592769-vm96nmdi7l5v46fsn08d6bgdnn5b8rmf.apps.googleusercontent.com"
							render={renderProps => (
						<Link  className="social" onClick={renderProps.onClick}><i className="fab fa-google-plus-g"></i></Link>
							)}
							buttonText="Login"
							onSuccess={responseGoogle}
							onFailure={responseGoogle}
							cookiePolicy={'single_host_origin'}
			                  />
						 {/* <a className="social"><TwitterLogin
							authCallback={authHandler}
							consumerKey='nbnLOkcCtGjy71Uj0Ac3pJbdX'
							consumerSecret='ZUx7l06GIgikR5ELUp5SVz7aa0ryr0u0FUKpikidqGWKjj0ad0'
							>
						<i className="fab fa-twitter"></i>
									</TwitterLogin></a> */}
							{/* <LinkedIn
							clientId="86bf5uhj67ssy0"
							onFailure={authHandler}
							onSuccess={authHandler}
							redirectUri="http://localhost:3000/authentication"
							renderElement={({ onClick, disabled }) => (
							<a className="social" onClick={onClick}><i className="fab fa-linkedin-in"></i></a>
								)}
								/>  */}
					</div>
					<span>or use your account</span>
					<input type="email" id='semail' placeholder="Email" required/>
					<input type="password" id='spassword' placeholder="Password" required/>
					{/* <a>Forgot your password?</a> */}
					<button  type="submit">Sign In</button>
					{
						signins===1?
						<CircularProgress color="secondary" />
						:
						<></>
					}
					
				</form>
			</div>
			<div className="overlay-container">
				<div className="overlay">
					<div className="overlay-panel overlay-left">
						<h1>Welcome Back!</h1>
						<p>To keep connected with us please login to your account</p>
						<button className="ghost" onClick={toggleClass}  id="signIn">Sign In</button>
					</div>
					<div className="overlay-panel overlay-right">
						<h1>Hello, Friend!</h1>
						<p>Enter your personal details and start your journey with us</p>
						<button className="ghost signUp" onClick={toggleClass}  id="signUp">Sign Up</button>
					</div>
				</div>
			</div>
		</div>

		{/* <footer>
			<p>
				Created with <i className="fa fa-heart"></i> by
				<a target="_blank" href="https://florin-pop.com">Florin Pop</a>
				- Read how I created this and how you can join the challenge
				<a target="_blank" href="https://www.florin-pop.com/blog/2019/03/double-slider-sign-in-up-form/">here</a>.
			</p>
		</footer> */}
	
		</div>

      
    )
}



const Home=()=>{
	const [cookies, setCookie] = useCookies(['user']);
	console.log(cookies.user)
	// const authHandler=(res)=>{
	// 	console.log('res')
	// 	console.log(res)
	// }
	
	return(
		<div style={{padding: '50px'}}>
			<center>
			<button className="btn btn-success" onClick={()=>{setCookie('user','');window.location.reload();}}>Signout</button>
			
			<div style={{marginTop: '20px'}}>hello! {cookies.user.first_name}</div>
			<div style={{marginTop: '20px'}}>Email:- {cookies.user.email}</div>
			<div style={{marginTop: '20px'}}>Username:- {cookies.user.username}</div>
			{
				cookies.user.last_name?
				<div style={{marginTop: '20px'}}>Last_name:- {cookies.user.last_name}</div>
				:
				<></>

			}
			<div style={{marginTop: '20px'}}>Provider:- {cookies.user.provider}</div>

			</center>

			
			
		</div>
	)
}