import React from "react"
import { HashLink as Linkk } from 'react-router-hash-link';
// import logo from "../Assets/images/Clown Dev B-01.png"
import logo from "../Assets/images/Clown Dev 4-01.png"
export default class Navbar extends React.Component {
    constructor(props) {
      super(props)
      this.state = {
          checked: null
      }
    }
  
    handleCheckbox = ( e) => {
        this.setState.checked = false
        document.getElementById("page-nav-toggle").click(); 
    } 

    render() {
        return (
        <>
            <div className="Navbar">
                <input id="page-nav-toggle" className="main-navigation-toggle" type="checkbox" checked={this.state.checked}/>
                    <label htmlFor="page-nav-toggle">
                    <svg className="icon--menu-toggle" viewBox="0 0 60 30">
                        <g className="icon-group">
                        <g className="icon--menu">
                            <path d="M 6 0  60 0" />
                            <path d="M 6 15 L 60 15" />
                            <path d="M 6 30 L 60 30" />
                        </g>
                        <g className="icon--close">
                            <path d="M 20 0 L 60 30" />
                            <path d="M 20 30 L 60 0" />
                        </g>
                        </g>
                    </svg>
                    </label>

                    <img src={logo} className="img-fluid nav-logo" alt=""/>

                    <nav className="main-navigation">
                    <ul>
                        <li><Linkk onClick={this.handleCheckbox} to="#hero">Home</Linkk></li>
                        <li><Linkk onClick={this.handleCheckbox} to="#services">Services</Linkk></li>
                        <li><Linkk onClick={this.handleCheckbox} to="#features">Why us</Linkk></li>
                        <li><Linkk onClick={this.handleCheckbox} to="#contact">Contact us</Linkk></li>
                        <li><Linkk onClick={this.handleCheckbox} to="#follow">Follow us</Linkk></li>
                    </ul>
                    </nav>
                </div> 

        </>
    )}
}
