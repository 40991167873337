import React from "react"

const Instalogo = () => {
    return (
        <>
        <svg className="fa" version="1.1" id="Capa_1"  x="0px" y="0px" viewBox="0 0 512 512">
            <g>
                <g>
                    <path d="M363.273,0H148.728C66.719,0,0,66.719,0,148.728v214.544C0,445.281,66.719,512,148.728,512h214.544
                        C445.281,512,512,445.281,512,363.273V148.728C512,66.719,445.281,0,363.273,0z M472,363.272C472,423.225,423.225,472,363.273,472
                        H148.728C88.775,472,40,423.225,40,363.273V148.728C40,88.775,88.775,40,148.728,40h214.544C423.225,40,472,88.775,472,148.728
                        V363.272z"/>
                </g>
            </g>
            <g>
                <g>
                    <path d="M256,118c-76.094,0-138,61.906-138,138s61.906,138,138,138s138-61.906,138-138S332.094,118,256,118z M256,354
                        c-54.037,0-98-43.963-98-98s43.963-98,98-98s98,43.963,98,98S310.037,354,256,354z"/>
                </g>
            </g>
            <g>
                <g>
                    <circle cx="396" cy="116" r="20"/>
                </g>
            </g>
            </svg>
        </>
    )
}

export default Instalogo;