import Home from './Screen/Home'
import React from "react";
import Designs from "./Screen/DesignModel"
import Jitsimeet from './Screen/Jitsimeet';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  
} from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../node_modules/bootstrap/dist/js/bootstrap.bundle";

import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import Authentication from './Components/Accountt'
import News from './Screen/News';
import YouTubeUpload from './Screen/YouTubeUpload';

export default function BasicExample() {
  
  return (
    <Router>
      <ReactNotification/>
      <div>   
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>

          <Route path='/authentication'>
            <Authentication />
          </Route>

          <Route path='/news'>
            <News />
          </Route>

          <Route path='/upload'>
            <YouTubeUpload />
          </Route>

          <Route path='/designs'>
            <Designs />
          </Route>

          <Route path='/meet'>
            <Jitsimeet />
          </Route>

        </Switch>
      </div>
    </Router>
  );
}
