import { JitsiMeeting } from '@jitsi/react-sdk'
import React from 'react'

export default function jitsimeet() {
  return (
    <div>
      <JitsiMeeting
        roomName={"YOUR_CUSTOM_ROOM_NAME"}
        getIFrameRef={(node) => (node.style.height = "800px")}
      />
    </div>
  )
}
