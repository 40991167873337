import React from "react";
import FBlogo from "../Assets/SVG/FBlogo"
import Instalogo from "../Assets/SVG/Instalogo"
import Whatsapplogo from "../Assets/SVG/Whatsapplogo"
// import {Link} from "react-router-dom";
const Footer = () => {
    return (
        <>
            <footer id='follow' className="nb-footer footer">
            <div className="container">
            <div className="row">
            <div className="col-sm-12">
            <div className="about">
                
                    
            <div className="social-btns">
                
                <div className="btn facebook" onClick={()=>window.open('https://www.facebook.com/ClownDev21/', '_blank')}>
                    <FBlogo></FBlogo>
                </div>

                <div className="btn instagram"  onClick={()=>window.open('https://www.instagram.com/clowndev21/', '_blank')}> 
                    <Instalogo></Instalogo>
                </div>

                <div className="btn whatsapp" onClick={()=>window.open('https://wa.me/message/7HDPI7BQSJD3L1', '_blank')}>               
                    <Whatsapplogo></Whatsapplogo>
                </div>

            </div>

            </div>
            </div>
            </div>
            </div>

                <section className="copyright">
                    <div className="container  text-center">
                    <div className="row">
                        <div className="row-sm-6">
                            <p>Bhubaneswar, Odisha, 751024</p>
                        </div>
                    <div className="row-sm-6">
                        <p>Copyright © 2021. www.clowndev.com</p>
                    </div>
                    </div>
                    </div>
                </section>
            </footer>
        </>
    );
}

export default Footer;