import  AboutUsImg from "../Assets/images/AboutUs.png"

const AboutUs = () =>{  
    
    return(
        <section id="features" className="features">

        <div className="container">
  
          <header className="section-header">
            <p>About us...</p>
          </header>
  
          <div className="row">
  
            <div className="col-lg-6">
              
              <div className="row align-self-center gy-4 mt-5">
  
                        <p className="aboutus-gradient-list">
                            We are a team of experienced tech enthusiasts who loves everything about technology. We operate with a vision to think ahead and provide innovative, cost effective and agile solutions to the information technology needs of our customers. Our process for developing technology you can use involves asking ourselves a few key questions to ensure we stay on the right track. We invite you to learn more about how we think, and then contact us for a consultation.
                        </p>
              </div>

            </div>
  
            <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
                <img src={AboutUsImg} className="img-fluid" alt=""/>
            </div>

          </div>
          </div>
    </section>
    )
}

export default AboutUs;