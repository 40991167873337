import  whyus from "../Assets/images/whyus.png"

const WhyUs = () =>{  
    
    return(
        <section id="features" className="features">

        <div className="container">
  
          <header className="section-header">
            <p>Why Us ?</p>
          </header>
  
          <div className="row">
  
            <div className="col-lg-6">
              <img src={whyus} className="img-fluid" alt=""/>
            </div>
  
            <div className="col-lg-6 mt-5 mt-lg-0 d-flex">
              <div className="row align-self-center gy-4">
  
                        <ol className="gradient-list">
                            <li>Work progress with regular interaction and continuous updates.</li>
                            <li>Humble and sincere pricing.</li>
                            <li>Bringing you the best and latest technology according to your needs.</li>
                            <li>Dedicated maintenance with design and architecture support.</li>
                            
                        </ol>
              </div>
            </div>
          </div>
          </div>
    </section>
    )
}

export default WhyUs;