import React from "react"
import WebDevelopment from "../Assets/SVG/WebDevelopment"
import IotDevelopment from "../Assets/SVG/IotDevelopment"
import ApiDevelopment from "../Assets/SVG/ApiDevelopment"

const Services = () => {
    return (
        <>

        <header className="section-header">
            <p>Our services</p>
        </header>

        <section id="services" className="services">
            
        <div className="row container">
        {/*--------------------------------Cared------------------------------------*/}
        
        <div className="col-lg-4 card">
            <div className="face face1">
                <div className="content">
                    <div className="icon">
                        <WebDevelopment className="fa"></WebDevelopment>
                        <h3 className="services-label">Web Development</h3>
                    </div>
                </div>
            </div>
            <div className="face face2">
                <div className="content">
                    <h5>
                    we create mobile-friendly and SEO-friendly stunning websites for your business. Contact us now and let's get started !
                    </h5>
                </div>
            </div>
        </div>
        {/*--------------------------------Cared------------------------------------*/}

        <div className="col-lg-4 mt-4 mt-lg-0 card">
            <div className="face face1">
                <div className="content">
                    <div className="icon">
                        <IotDevelopment className="fa"></IotDevelopment>
                        <h3 className="services-label">IoT and Automation</h3>
                    </div>
                </div>
            </div>
            <div className="face face2">
                <div className="content">
                    <h5>
                    From automating boring stuffs to making machines communicate, so that you can enjoy
                    </h5>
                </div>
            </div>
        </div>

        {/*--------------------------------Cared------------------------------------*/}
            <div className="col-lg-4 mt-4 mt-lg-0 card">
                <div className="face face1">
                    <div className="content">
                        <div className="icon">
                            <ApiDevelopment className="fa"></ApiDevelopment>
                            <h3 className="services-label">API Development</h3>
                        </div>
                    </div>
                </div>
                <div className="face face2">
                    <div className="content">
                        <h5>
                            Discover the power APIs for a smoother integration
                        </h5>
                    </div>
                </div>
            </div>
    </div>
    </section>
        </>
    )
}

export default Services;