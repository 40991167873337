import React from 'react';


const HeroName =() => {
    return (
        <div >
    <svg id="logo" width="100%" height="100%" viewBox="0 0 600 95" fill="none">

    <path d="M2.80844 49.12C2.80844 40.96 4.64844 33.64 8.32844 27.16C12.0084 20.6 17.0084 15.48 23.3284 11.8C29.7284 8.12 36.8084 6.27999 44.5684 6.27999C53.6884 6.27999 61.6484 8.48 68.4484 12.88C75.2484 17.28 80.2084 23.52 83.3284 31.6H70.2484C67.9284 26.56 64.5684 22.68 60.1684 19.96C55.8484 17.24 50.6484 15.88 44.5684 15.88C38.7284 15.88 33.4884 17.24 28.8484 19.96C24.2084 22.68 20.5684 26.56 17.9284 31.6C15.2884 36.56 13.9684 42.4 13.9684 49.12C13.9684 55.76 15.2884 61.6 17.9284 66.64C20.5684 71.6 24.2084 75.44 28.8484 78.16C33.4884 80.88 38.7284 82.24 44.5684 82.24C50.6484 82.24 55.8484 80.92 60.1684 78.28C64.5684 75.56 67.9284 71.68 70.2484 66.64H83.3284C80.2084 74.64 75.2484 80.84 68.4484 85.24C61.6484 89.56 53.6884 91.72 44.5684 91.72C36.8084 91.72 29.7284 89.92 23.3284 86.32C17.0084 82.64 12.0084 77.56 8.32844 71.08C4.64844 64.6 2.80844 57.28 2.80844 49.12Z" stroke="#DC4A38" strokeWidth="4" mask="url(#path-1-outside-1)"/>
    <path d="M110.504 2.2V91H99.5838V2.2H110.504Z" stroke="#DC4A38" strokeWidth="4" mask="url(#path-1-outside-1)"/>
    <path d="M157.795 92.08C151.635 92.08 146.035 90.68 140.995 87.88C136.035 85.08 132.115 81.12 129.235 76C126.435 70.8 125.035 64.8 125.035 58C125.035 51.28 126.475 45.36 129.355 40.24C132.315 35.04 136.315 31.08 141.355 28.36C146.395 25.56 152.035 24.16 158.275 24.16C164.515 24.16 170.155 25.56 175.195 28.36C180.235 31.08 184.195 35 187.075 40.12C190.035 45.24 191.515 51.2 191.515 58C191.515 64.8 189.995 70.8 186.955 76C183.995 81.12 179.955 85.08 174.835 87.88C169.715 90.68 164.035 92.08 157.795 92.08ZM157.795 82.48C161.715 82.48 165.395 81.56 168.835 79.72C172.275 77.88 175.035 75.12 177.115 71.44C179.275 67.76 180.355 63.28 180.355 58C180.355 52.72 179.315 48.24 177.235 44.56C175.155 40.88 172.435 38.16 169.075 36.4C165.715 34.56 162.075 33.64 158.155 33.64C154.155 33.64 150.475 34.56 147.115 36.4C143.835 38.16 141.195 40.88 139.195 44.56C137.195 48.24 136.195 52.72 136.195 58C136.195 63.36 137.155 67.88 139.075 71.56C141.075 75.24 143.715 78 146.995 79.84C150.275 81.6 153.875 82.48 157.795 82.48Z" stroke="#DC4A38" strokeWidth="4" mask="url(#path-1-outside-1)"/>
    <path d="M293.473 25.24L272.953 91H261.673L245.833 38.8L229.993 91H218.713L198.073 25.24H209.233L224.353 80.44L240.673 25.24H251.833L267.793 80.56L282.673 25.24H293.473Z" stroke="#DC4A38" strokeWidth="4" mask="url(#path-1-outside-1)"/>
    <path d="M336.35 24.04C344.35 24.04 350.83 26.48 355.79 31.36C360.75 36.16 363.23 43.12 363.23 52.24V91H352.43V53.8C352.43 47.24 350.79 42.24 347.51 38.8C344.23 35.28 339.75 33.52 334.07 33.52C328.31 33.52 323.71 35.32 320.27 38.92C316.91 42.52 315.23 47.76 315.23 54.64V91H304.31V25.24H315.23V34.6C317.39 31.24 320.31 28.64 323.99 26.8C327.75 24.96 331.87 24.04 336.35 24.04Z" stroke="#DC4A38" strokeWidth="4" mask="url(#path-1-outside-1)"/>
    <path d="M407.108 7.36C416.228 7.36 424.108 9.08 430.748 12.52C437.468 15.88 442.588 20.72 446.108 27.04C449.708 33.36 451.508 40.8 451.508 49.36C451.508 57.92 449.708 65.36 446.108 71.68C442.588 77.92 437.468 82.72 430.748 86.08C424.108 89.36 416.228 91 407.108 91H381.068V7.36H407.108ZM407.108 82C417.908 82 426.148 79.16 431.828 73.48C437.508 67.72 440.348 59.68 440.348 49.36C440.348 38.96 437.468 30.84 431.708 25C426.028 19.16 417.828 16.24 407.108 16.24H391.988V82H407.108Z" stroke="#012970" strokeWidth="4" mask="url(#path-1-outside-1)"/>
    <path d="M525.912 55.6C525.912 57.68 525.792 59.88 525.552 62.2H472.992C473.392 68.68 475.592 73.76 479.592 77.44C483.672 81.04 488.592 82.84 494.352 82.84C499.072 82.84 502.992 81.76 506.112 79.6C509.312 77.36 511.552 74.4 512.832 70.72H524.592C522.832 77.04 519.312 82.2 514.032 86.2C508.752 90.12 502.192 92.08 494.352 92.08C488.112 92.08 482.512 90.68 477.552 87.88C472.672 85.08 468.832 81.12 466.032 76C463.232 70.8 461.832 64.8 461.832 58C461.832 51.2 463.192 45.24 465.912 40.12C468.632 35 472.432 31.08 477.312 28.36C482.272 25.56 487.952 24.16 494.352 24.16C500.592 24.16 506.112 25.52 510.912 28.24C515.712 30.96 519.392 34.72 521.952 39.52C524.592 44.24 525.912 49.6 525.912 55.6ZM514.632 53.32C514.632 49.16 513.712 45.6 511.872 42.64C510.032 39.6 507.512 37.32 504.312 35.8C501.192 34.2 497.712 33.4 493.872 33.4C488.352 33.4 483.632 35.16 479.712 38.68C475.872 42.2 473.672 47.08 473.112 53.32H514.632Z" stroke="#012970" strokeWidth="4" mask="url(#path-1-outside-1)"/>
    <path d="M564.806 80.92L585.206 25.24H596.846L571.046 91H558.326L532.526 25.24H544.286L564.806 80.92Z" stroke="#012970" strokeWidth="4" mask="url(#path-1-outside-1)"/>
    </svg>
                
        </div>
    )
}

export default HeroName;