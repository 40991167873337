import React, { useState,useEffect } from 'react';
import axios from "axios"
import {gurl} from '../Components/Global'

function Designs() {
    const [photos,setPhotos] = useState([]);
    useEffect(() => {
        window.preloder();

        axios.get(gurl+'app1/all_graphicsdesigns')
      .then((data)=>{
        setPhotos(data.data)
      })
      }, [])
    
    return(
      <div>
     
     <h1>Masonry Gallery</h1>

<div class="gallery" id="gallery">
        {
            
            photos.map((item) => (
               
                <div class="gallery-item">
                    <div class="content"><img src={item.image} alt="" /></div>
                </div>
            ))
        }
    {/* <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,care" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,studied" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,substance" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,choose" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,past" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,lamp" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,yet" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,eight" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,crew" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,event" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,instrument" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,practical" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,pass" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,bigger" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,number" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,feature" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,line" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,railroad" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,pride" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,too" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,bottle" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,base" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,cell" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,bag" alt="" /></div>
    </div>
    <div class="gallery-item">
        <div class="content"><img src="https://source.unsplash.com/random/?tech,card" alt="" /></div>
    </div> */}
</div>
        
      </div>
    )
    
  }
  
  export default Designs;