import React,  { useState } from 'react'
import axios from "axios"
import { store } from 'react-notifications-component';
import { CircularProgress } from '@material-ui/core';
import {Link} from "react-router-dom";

const YouTubeUpload = () => {
    const [name,setName] = useState('');
    const [email,setEmail] = useState('');
    const [phone,setPhone] = useState('');
    const [subject,setSubject] = useState('');
    const [message,setMesage] = useState('');
    const [contacts,setContacts] = useState('');

    const Sendclient=event=>{
        event.preventDefault();
        // alert("successful")
        Noti('Uploading VideoPlease wait','success')
        setTimeout(function(){
            alert("Upload successful"); 
            document.getElementById("goHome").click()
    }, 20000);//wait 2 seconds

    }

    

return (
  <>
    <section id="contact" className="contact">

    <div className="container" data-aos="fade-up">

      <header className="section-header">
              <p>Upload video - ClownDev !!</p>
      </header>

 



    <div className="col-lg-12">
      <form  className="php-email-form" onSubmit={Sendclient}>
        <div className="row gy-4">

          <div className="col-md-12">
            <input type="text" className="form-control" value={name} onChange={(e)=>setName(e.target.value)}  placeholder="Employee ID" required/>
          </div>

          <div className="col-md-6">
            <input type="email"  className="form-control" value={email} onChange={(e)=>setEmail(e.target.value)} placeholder="Enter your verified email" required/>
          </div>

          <div className="col-md-6 ">
            <input type="text" className="form-control" value={phone} onChange={(e)=>setPhone(e.target.value)} placeholder="Video Title" required/>
          </div>

          <div className="col-md-12">
            <input type="file" className="form-control" value={subject} onChange={(e)=>setSubject(e.target.value)} placeholder="Video" required/>
          </div>

          <div className="col-md-12">
            <textarea className="form-control" value={message} onChange={(e)=>setMesage(e.target.value)} rows="2" placeholder="Video Description" required></textarea>
          </div>

          <div className="col-md-12 text-center">
            <div className="loading">Loading</div>
            <div className="error-message"></div>
            <div className="sent-message">Your message has been sent. Thank you!</div>

            <button type="submit">Upload</button>
            <Link to="/" id="goHome" hidden>Home</Link>
            {
						contacts===1?
            <div>
						<CircularProgress color="secondary" />
            </div>
						:
						<></>
					}
          </div>

        </div>
      </form>

    </div>

  </div>

</section>
  </>
)
}

export default YouTubeUpload;

export const Noti=(message,type)=>{
  store.addNotification({
      title: 'ClownDev',
      message: message,
      type: type,
      insert: "top",
      container: "top-right",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 20000,
        onScreen: true
      }
    });
}