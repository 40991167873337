import React from "react"

const ApiDevelopment = () => {
    return (
        <>
        

		<svg version="1.1" id="Layer_1"  height="100" width="100" viewBox="0 0 300.014 300.014" >
<g>
	<g>
		<g>
			<path d="M111.297,87.111h-6.561c9.89-7.989,16.239-20.197,16.239-33.871V36.034c5.758-3.358,9.677-9.532,9.677-16.665
				c0-10.674-8.681-19.355-19.355-19.355H43.556C32.882,0.014,24.2,8.695,24.2,19.369c0,7.132,3.919,13.306,9.677,16.665V53.24
				c0,13.674,6.348,25.882,16.239,33.871h-6.561c-24.01,0-43.548,19.539-43.548,43.548v48.387c0,16.011,13.021,29.032,29.032,29.032
				v91.936h96.774v-33.871c10.674,0,19.355-8.681,19.355-19.355v-46.156c6.155-5.506,9.677-13.239,9.677-21.585V130.66
				C154.846,106.65,135.307,87.111,111.297,87.111z M33.879,19.369c0-5.337,4.34-9.677,9.677-9.677h67.742
				c5.337,0,9.677,4.34,9.677,9.677c0,5.337-4.34,9.677-9.677,9.677H43.556C38.218,29.046,33.879,24.706,33.879,19.369z
				 M108.848,38.724c-3.711,5.894-10.229,9.677-17.502,9.677H63.505c-7.273,0-13.79-3.784-17.502-9.677H108.848z M43.556,53.24
				v-2.734c5.39,4.718,12.368,7.573,19.95,7.573h27.842c7.582,0,14.56-2.855,19.95-7.573v2.734
				c0,18.677-15.194,33.871-33.871,33.871S43.556,71.918,43.556,53.24z M80.262,101.627H74.59l-4.839-4.839H85.1L80.262,101.627z
				 M82.265,182.69c2.744,2.105,5.637,4.123,8.7,6.034h-8.7V182.69z M56.067,96.789l14.516,14.516h2.003v48.933
				c-20.341-23.729-26.617-52.015-28.393-63.449H56.067z M29.039,198.402c-10.675,0-19.355-8.681-19.355-19.355v-4.839h19.355
				V198.402z M29.039,164.53H9.684v-33.87c0-15.576,10.582-28.694,24.924-32.633c2.134,13.752,10.152,49.408,37.979,76.345v14.352
				H38.716v-67.742h-9.677V164.53z M72.588,290.337H38.716v-9.677h33.871V290.337z M116.135,290.337H82.265v-9.677h33.871V290.337z
				 M116.135,270.982H82.265v-53.226h-9.677v53.226H38.716v-72.581h67.742v48.387c0,7.132,3.919,13.306,9.677,16.665V270.982z
				 M135.492,246.789c-0.001,5.337-4.341,9.677-9.678,9.677c-5.337,0-9.677-4.34-9.677-9.677v-29.923
				c1.519,0.542,3.135,0.89,4.839,0.89h9.677c1.703,0,3.319-0.348,4.839-0.89V246.789z M116.135,203.24v-4.839h19.355v4.839
				c0,2.671-2.172,4.839-4.839,4.839h-9.677C118.309,208.079,116.135,205.911,116.135,203.24z M145.169,179.047
				c0,3.488-1.007,6.769-2.7,9.677h-16.655v-14.516h19.355V179.047z M145.169,164.531h-19.355v-43.548h-9.677v67.742h-3.847
				c-11.956-4.824-21.847-11.284-30.024-18.595v-58.824h2.003L98.785,96.79h12.513c18.677,0,33.871,15.194,33.871,33.871V164.531z"
				/>
			<path d="M295.168,19.369V5.24L232.265,0l-62.903,5.24v14.129h-4.839v9.677h4.839v164.516h48.387v96.774h-14.516v9.677h14.516
				h29.032h14.516v-9.677h-14.516v-96.774h48.387V29.047h4.839v-9.678H295.168z M237.104,290.337h-9.677v-96.774h9.677V290.337z
				 M285.491,183.885h-38.71h-29.032h-38.71V29.047h106.452V183.885z M285.492,19.37H179.039v-5.226l53.226-4.437l53.226,4.437
				V19.37z"/>
			<path d="M193.556,82.273h77.419V62.918h4.839V53.24h-4.839h-77.419h-4.839v9.677h4.839V82.273z M203.233,62.918h58.065v9.677
				h-58.065V62.918z"/>
			<path d="M193.556,174.208h77.419v-62.903h-77.419V174.208z M203.233,120.982h58.065v43.548h-58.065V120.982z"/>
			<rect x="208.072" y="125.821" width="48.387" height="9.677"/>
			<rect x="208.072" y="140.337" width="29.032" height="9.677"/>
			<rect x="246.781" y="140.337" width="9.677" height="9.677"/>
		</g>
	</g>
</g>

</svg>

        </>
    )
}

export default ApiDevelopment;